import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utilities/Api';

interface InitialState {
    newNotifications: {
        schedule: number;
        discussions: number;
        approvals: number;
    };
    isLoadingNotifications: boolean;
    allNotifications: [];
}

let initialState: InitialState = {
    newNotifications: {
        schedule: 0,
        discussions: 0,
        approvals: 0,
    },
    isLoadingNotifications: true,
    allNotifications: [],
};

export const fetchScheduleRequestsNotifications = createAsyncThunk('notifications/fetchScheduleRequests', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/notifications?types=event,augmentation`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const viewedScheduleRequestNotifications = createAsyncThunk('notifications/viewedScheduleRequests', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/notifications/view?type=schedule_request`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchDiscussionsNotifications = createAsyncThunk('notifications/fetchDiscussions', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/notifications?types=discussion`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const viewedDiscussionNotifications = createAsyncThunk('notifications/viewedDiscussionNotifications', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/notifications/view?type=discussion`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchApprovalsNotifications = createAsyncThunk('notifications/fetchApprovals', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/notifications/?type=approval`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const viewedApprovalNotifications = createAsyncThunk('notifications/viewedApprovalNotifications', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/notifications/view?type=approval`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteNotification = createAsyncThunk('notifications/deleteNotification', async (notification: any, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    try {
        const response = await axios({
            method: 'delete',
            url: `${BASE_URL}/v1/notifications/${notification.id}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const readNotification = createAsyncThunk('notifications/readNotification', async (notification: any, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    const data = { read: true };

    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/notifications/${notification.id}`,
            data: data,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const readNotificationForDiscussion = createAsyncThunk('discussions/readNotificationForDiscussion', async (discussion: any, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    const data = { read: true };

    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/discussions/${discussion.id}/notification`,
            data: data,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchScheduleRequestsNotifications.fulfilled]: (state, action) => {
            state.newNotifications.schedule = action.payload.filter((x: any) => x.read !== true).length;
            state.isLoadingNotifications = false;
            state.allNotifications = action.payload;
        },
        // @ts-ignore
        [fetchScheduleRequestsNotifications.pending]: (state, action) => {},
        // @ts-ignore
        [fetchScheduleRequestsNotifications.rejected]: (state, action) => {},
        // @ts-ignore
        [viewedScheduleRequestNotifications.pending]: (state, action) => {
            state.newNotifications.schedule = 0;
        },
        // @ts-ignore
        [fetchDiscussionsNotifications.fulfilled]: (state, action) => {
            state.newNotifications.discussions = action.payload.filter((x: any) => x.read !== true).length;
        },
        // @ts-ignore
        [fetchDiscussionsNotifications.pending]: (state, action) => {},
        // @ts-ignore
        [fetchDiscussionsNotifications.rejected]: (state, action) => {},
        // @ts-ignore
        [viewedDiscussionNotifications.pending]: (state, action) => {
            state.newNotifications.discussions = 0;
        },
        // @ts-ignore
        [fetchApprovalsNotifications.fulfilled]: (state, action) => {
            state.newNotifications.approvals = action.payload.count;
        },
        // @ts-ignore
        [fetchApprovalsNotifications.pending]: (state, action) => {},
        // @ts-ignore
        [fetchApprovalsNotifications.rejected]: (state, action) => {},
        // @ts-ignore
        [viewedApprovalNotifications.pending]: (state, action) => {
            state.newNotifications.approvals = 0;
        },
        // @ts-ignore
        [deleteNotification.fulfilled]: (state, action) => {
            state.allNotifications = state.allNotifications.filter((notification: any) => notification.id !== action.meta.arg.id);
            state.newNotifications.schedule = state.allNotifications.filter((x: any) => x.read !== true).length;
        },
        // @ts-ignore
        [readNotification.fulfilled]: (state, action) => {
            state.allNotifications = state.allNotifications.map((notification: any) => {
                if (notification.id === action.meta.arg.id) return { ...notification, read: true };

                return notification;
            });

            state.newNotifications.schedule = state.newNotifications.schedule - 1;
        },
    },
});

export default notificationsSlice.reducer;
