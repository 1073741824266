import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_URL } from '../utilities/Api';

interface CrewMember {
    id: number;
    name: string;
    first_name: string;
    last_name: string;
    rank_text: string;
    qualifications_text: string;
    label: string;
    employment_status: string;
    office: {
        id: number;
        name: string;
    };
}

export interface TimeOffRequest {
    id: number;
    crew_member: CrewMember;
    start_datetime: '2022-05-04T08:00:00-04:00';
    end_datetime: '2022-05-04T09:00:00-04:00';
    reason: null;
    created_at: '2022-05-04T09:00:39.287193-04:00';
    updated_at: '2022-05-04T09:00:39.287213-04:00';
    time_off_request_type: 'A';
}

interface InitialState {
    all_commitments: Array<TimeOffRequest>;
    isLoading: boolean;
}

let initialState: InitialState = {
    all_commitments: [],
    isLoading: false,
};

export const fetchUsersTimeOff = createAsyncThunk('users/fetchUsersTimeOff', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/users/time_off`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteTimeOffRequest = createAsyncThunk('users/deleteTimeOffRequest', async (timeOffRequestId: string, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth, users } = getState();
    try {
        await axios({
            method: 'delete',
            url: `${BASE_URL}/v1/users/time_off/${timeOffRequestId}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Time off request deleted.');
        return timeOffRequestId;
    } catch (error: any) {
        toast.error('An error occured.');
        return rejectWithValue(error.response.data);
    }
});

export const createCommitment = createAsyncThunk('users/createCommitment', async (data: any, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        await axios({
            method: 'post',
            url: `${BASE_URL}/v1/time_off`,
            data: { ...data },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Commitment created.');
    } catch (error: any) {
        toast.error('An error occured.');
        return rejectWithValue(error.response.data);
    }
});

export const updateCommitment = createAsyncThunk('users/updateCommitment', async (data: any, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        await axios({
            method: 'put',
            url: `${BASE_URL}/v1/time_off/${data.id}`,
            data: { ...data },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Commitment updated.');
    } catch (error: any) {
        toast.error('An error occured.');
        return rejectWithValue(error.response.data);
    }
});

const commitmentsSlice = createSlice({
    name: 'commitments',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchUsersTimeOff.fulfilled]: (state, action) => {
            state.all_commitments = action.payload;
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchUsersTimeOff.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchUsersTimeOff.rejected]: (state) => {
            state.isLoading = false;
        },
        // @ts-ignore
        [deleteTimeOffRequest.fulfilled]: (state, action) => {
            state.all_commitments = state.all_commitments.filter((timeOffRequest: any) => timeOffRequest.id !== action.payload);
        },
        // @ts-ignore
        [createCommitment.rejected]: (state, action) => {
            state.error = action.payload?.detail;
        },
    },
});

export default commitmentsSlice.reducer;
