import { DefaultTheme } from 'styled-components';
export const Theme: DefaultTheme = {
    spacing: {
        zero: '0px',
        one: '4px',
        two: '8px',
        three: '12px',
        four: '16px',
        five: '24px',
        six: '32px',
        seven: '48px',
        eight: '60px',
    },
    typography: {
        xs: 'font-size: 0.75rem; line-height: 1rem;',
        sm: 'font-size: 0.875rem; line-height: 1.25rem;',
        default: 'font-size: 1rem; line-height: 1.5rem;',
        lg: 'font-size: 1.125rem; line-height: 1.75rem;',
        xl: 'font-size: 1.25rem; line-height: 1.75rem;',
        xl2: 'font-size: 1.5rem; line-height: 2rem;',
        xl3: 'font-size: 1.875rem; line-height: 2.5rem;',
        xl4: 'font-size: 2.25rem; line-height: 2.5rem;',
    },
    borderRadius: {
        one: '4px',
        two: '8px',
        three: '12px',
        four: '24px',
    },

    breakpoints: {
        sm: '640px',
        md: '768px',
        lg: '1024px',
        xl: '1280px',
    },
    shadow: {
        one: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
        two: '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
        three: '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        four: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',
        five: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
    },
    colors: {
        siteBackground: '#000000',
        cardBackground: {
            one: '#171718',
        },
        separatorLineColor: '#444',
        gray: {
            // possible blue gray range
            one: '#e6e9f5',
            two: '#c7cbd6',
            three: '#969db3',
            four: '#949bb0',
            five: '#8992ad',
            six: '#4e5b82',
            seven: '#42528f',
            eight: '#2b3763',
            nine: '#212B4E',
            ten: '#182039',
        },
        primary: {
            one: '#EFF6FF',
            two: '#DBEAFE',
            three: '#BFDBFE',
            four: '#93C5FD',
            five: '#60A5FA',
            six: '#3B82F6',
            seven: '#2563EB',
            eight: '#1D4ED8',
            nine: '#1E40AF',
            ten: '#1E3A8A',
        },
        secondary: {
            one: '#f5f3ff',
            two: '#ede9fe',
            three: '#ddd6fe',
            four: '#c4b5fd',
            five: '#a78bfa',
            six: '#8b5cf6',
            seven: '#7c3aed',
            eight: '#6d28d9',
            nine: '#5b21b6',
            ten: '#4c1d95',
        },
        error: {
            one: '#fef2f2',
            two: '#fee2e2',
            three: '#fecaca',
            four: '#fca5a5',
            five: '#f87171',
            six: '#ef4444',
            seven: '#dc2626',
            eight: '#b91c1c',
            nine: '#991b1b',
            ten: '#7f1d1d',
        },
        success: {
            // green
            one: '#F0FDF4',
            two: '#DCFCE7',
            three: '#BBF7D0',
            four: '#86EFAC',
            five: '#4ADE80',
            six: '#22C55E',
            seven: '#16A34A',
            eight: '#15803D',
            nine: '#166534',
            ten: '#14532D',
        },
        warning: {
            // amber tailwind css
            one: '#FFFBEB',
            two: '#FEF3C7',
            three: '#FDE68A',
            four: '#FCD34D',
            five: '#FBBF24',
            six: '#F59E0B',
            seven: '#D97706',
            eight: '#B45309',
            nine: '#92400E',
            ten: '#78350F',
        },
    },
    gradient: {
        primary: 'linear-gradient(135deg, #1D4ED8, #14B8A6);',
        secondary: 'linear-gradient(135deg, #1D4ED8, #155e75);',
        accent: 'linear-gradient(135deg, #ef4444, #facc15);',
    },
};
