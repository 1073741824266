import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import toast from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';

import { CalendarEvent, CalendarEventTemplate, CalendarEventDiscussion } from '../static/interfaces/calendar';
import { EventSlot, EventSlotFillRequest, EventType, LookbackEventType, Location } from '../static/interfaces/scheduling';
import { Person } from '../static/interfaces/users';
import { BASE_URL } from '../utilities/Api';

import { mapBackendObjectToOption } from '../utilities/mappers';

export enum Duplicate {
    // eslint-disable-next-line no-unused-vars
    no = 'no',
    // eslint-disable-next-line no-unused-vars
    clearCrew = 'clearCrew',
    // eslint-disable-next-line no-unused-vars
    copyCrew = 'copyCrew',
    // eslint-disable-next-line no-unused-vars
    autoFill = 'autoFill',
}

interface Filters {
    showUserMeFilter: boolean;
    eventTypeFilter: string;
    rolesFilter: string[];
    airframeTypesFilter: string[];
    airframesFilter: string;
    projectFilter: string;
    usersFilter: string;
    start_date: string;
    end_date: string;
}

interface StartDateAndTime {
    eventStartIso: string;
    eventEndIso: string;
}

export interface AirFrames {
    eventsByDate: {
        [key: string]: Array<CalendarEvent>;
    };
    airframes: Array<Person>;
    airframeIds: Array<string>;
}
export interface Personnel {
    eventsByDate: {
        [key: string]: Array<CalendarEvent>;
    };
    personnel: Array<Person>;
    personnelIds: Array<string>;
    qualifications_text: string;
}

interface InitialState {
    events: Array<CalendarEvent>;
    rainbow_events: {
        airframes: AirFrames;
        personnel: Personnel;
    };
    eventTemplates: Array<CalendarEventTemplate>;
    activity: any;
    holidays: any;
    isLoading: boolean;
    isSavingEventTemplate: boolean;
    isLoadingRainbow: boolean;
    error: string;
    filters: Filters;
    loadingDiscussions: boolean;
    discussions: Array<CalendarEventDiscussion>; // This is for event-specific discussions
    isLoadingAllDiscussions: boolean;
    allDiscussions: Array<CalendarEventDiscussion>;
    rainbowError: string;
    currentEventUniqueIdentifier: string | null;
    currentEventType: EventType | null;
    currentEventDateAndTime: StartDateAndTime | null;
    currentEventSlots: Array<EventSlot>;
    currentSlotFillRequest: EventSlotFillRequest | null;
    currentEventArmsMissions: Array<LookbackEventType>;
    currentEventLocation: Location | null;
    fillingEmptySlots: boolean;
    slotFillError: string | null;
    currentEventName: string | null;
    currentEventNotes: string | null;
    currentEventWebsocketId: string;
    currentEventDuplicate: Duplicate;
    currentEventDuplicationDates: string[];
    priority: number;
    conflicts: {
        loading: boolean;
        data: any;
        error: any;
    };
}

let initialState: InitialState = {
    // @ts-expect-error Previous TS errors
    events: null,
    conflicts: {
        loading: false,
        data: {},
        error: null,
    },
    rainbow_events: {
        airframes: {
            airframeIds: [],
            airframes: [],
            eventsByDate: {},
        },
        // @ts-expect-error Previous TS errors
        personnel: {
            personnelIds: [],
            personnel: [],
            eventsByDate: {},
        },
    },
    eventTemplates: [],
    holidays: {},
    activity: [],
    isLoading: false,
    isSavingEventTemplate: false,
    isLoadingRainbow: false,
    error: '',
    filters: {
        showUserMeFilter: false,
        usersFilter: '',
        eventTypeFilter: '',
        rolesFilter: [],
        airframeTypesFilter: [],
        airframesFilter: '',
        projectFilter: '',
        start_date: '',
        end_date: '',
    },
    loadingDiscussions: true,
    discussions: [], // This is for event-specific discussions
    isLoadingAllDiscussions: true,
    allDiscussions: [], // This is for all discussions
    rainbowError: '',
    currentEventUniqueIdentifier: null,
    currentEventType: null,
    currentEventDateAndTime: null,
    currentEventSlots: [],
    currentSlotFillRequest: null,
    currentEventArmsMissions: [],
    fillingEmptySlots: false,
    slotFillError: null,
    currentEventName: null,
    currentEventNotes: null,
    currentEventLocation: null,
    currentEventWebsocketId: uuidv4(), // TODO: Make this dynamic based on the selected event ID.
    currentEventDuplicate: Duplicate.no,
    currentEventDuplicationDates: [],
    priority: 4,
};

function eventTransformer(eventData: any) {
    const { start_datetime, end_datetime } = eventData;
    const startMoment = moment(start_datetime);
    const endMoment = moment(end_datetime);
    let title = eventData.event_type?.name || 'Event';
    let heading = '';
    let subtitle = '';

    let defaultEventSubtitle = ` `;

    if (eventData.event_name) {
        subtitle = eventData.event_name;
        heading = defaultEventSubtitle;
    } else if (eventData.event_type?.name) {
        subtitle = defaultEventSubtitle;
    }

    return {
        ...eventData,
        title: title,
        subtitle: subtitle,
        heading: heading,
        start: startMoment.format(),
        end: endMoment.format(),
    } as CalendarEvent;
}

export const fetchEvents = createAsyncThunk('events/fetchEvents', async ({ start, end }: { start?: string; end?: string }, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response: any = await axios.get(`${BASE_URL}/v1/events`, {
            params: {
                ...(start ? { start_date: start } : {}),
                ...(end ? { end_date: end } : {}),
            },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        return response.data.map((event: any) => eventTransformer(event));
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchEventDetails = createAsyncThunk('events/fetchEventDetails', async (id: string, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response: any = await axios.get(`${BASE_URL}/v1/events/${id}`, {
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

interface GetRainbowEventsParams {
    start: string;
    end: string;
}

export const getRainbowEvents = createAsyncThunk('events/getRainbowEvents', async ({ start, end }: GetRainbowEventsParams, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/events/rainbow?start=${start}T00:00:00Z&end=${end}T23:59:59Z`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        // Next we do setup the personnel
        const returnPersonnel = {
            personnelIds: [],
            personnel: [],
            eventsByDate: {},
        };
        const eventsWithPersonnel = response.data.personnel_and_events;
        for (let i = 0; i < eventsWithPersonnel.length; i++) {
            const thisEventWithPersonnel = eventsWithPersonnel[i];
            const thisEvent = thisEventWithPersonnel.event;
            // Setup the event
            const startDate = moment(thisEvent.start).toDate();
            const startDateIso = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000).toISOString().split('T')[0];
            // Setup the personnel
            for (let j = 0; j < thisEventWithPersonnel.personnel.length; j++) {
                const thisPerson = thisEventWithPersonnel.personnel[j];
                const thisPersonUser = thisPerson.user;
                if (!thisPersonUser) {
                    continue;
                }
                // @ts-expect-error Previous TS errors
                if (returnPersonnel.personnelIds.indexOf(thisPersonUser.id) === -1) {
                    // @ts-expect-error Previous TS errors
                    returnPersonnel.personnelIds.push(thisPersonUser.id);
                    // @ts-expect-error Previous TS errors
                    returnPersonnel.personnel.push(thisPersonUser);
                    // @ts-expect-error Previous TS errors
                    returnPersonnel.eventsByDate[thisPersonUser.id] = {};
                }
                // @ts-expect-error Previous TS errors
                if (!returnPersonnel.eventsByDate[thisPersonUser.id][startDateIso]) {
                    // @ts-expect-error Previous TS errors
                    returnPersonnel.eventsByDate[thisPersonUser.id][startDateIso] = [];
                }
                // @ts-expect-error Previous TS errors
                returnPersonnel.eventsByDate[thisPersonUser.id][startDateIso].push(eventTransformer(thisEvent));
            }
        }
        for (let i = 0; i < response.data.all_personnel.length; i++) {
            const thisPersonnelItem = response.data.all_personnel[i];
            if (!thisPersonnelItem) {
                continue;
            }

            // @ts-expect-error Previous TS errors
            if (returnPersonnel.personnelIds.indexOf(thisPersonnelItem.id) === -1) {
                // @ts-expect-error Previous TS errors
                returnPersonnel.personnelIds.push(thisPersonnelItem.id);
                // @ts-expect-error Previous TS errors
                returnPersonnel.personnel.push(thisPersonnelItem);
                // @ts-expect-error Previous TS errors
                returnPersonnel.eventsByDate[thisPersonnelItem.id] = {};
            }
        }
        return {
            personnel: returnPersonnel,
        };
    } catch (error: any) {
        console.warn('error: ', error);
        return rejectWithValue(error.response.data);
    }
});

export const fetchEventsWithFilters = createAsyncThunk('events/fetchEventsWithFilters', async (filters: Filters, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth, users } = getState();
    const currentUser = users.me.id;

    const params = {
        ...(filters.eventTypeFilter && {
            // @ts-expect-error Previous TS errors
            event_type: filters.eventTypeFilter.id,
        }),
        ...(filters.showUserMeFilter && { users: currentUser }),
        // @ts-expect-error Previous TS errors
        ...(filters.usersFilter && { by_user: filters.usersFilter.id }),
        ...(filters.projectFilter && { event_name: filters.projectFilter }),
        ...(filters.start_date && { start_date: filters.start_date }),
        ...(filters.end_date && { end_date: filters.end_date }),
    };

    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/events`,
            params: params,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        return {
            filters,
            data: response.data.map((event: any) => eventTransformer(event)),
        };
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createEvent = createAsyncThunk('events/createEvent', async (blockInfo, { getState, rejectWithValue, dispatch }) => {
    // @ts-expect-error Previous TS errors
    const { auth, calendar } = getState();
    return submitEvent({ calendar, auth, rejectWithValue, isUpdate: false, dispatch, blockInfo });
});

export const updateEvent = createAsyncThunk('events/updateEvent', async (blockInfo, { getState, rejectWithValue, dispatch }) => {
    // @ts-expect-error Previous TS errors
    const { auth, calendar } = getState();

    return submitEvent({ calendar, auth, rejectWithValue, isUpdate: true, dispatch, blockInfo });
});

export const updateEventWithEventDetails = createAsyncThunk('events/updateEventWithDetails', async (eventDetails: any, { getState, rejectWithValue, dispatch }) => {
    // @ts-expect-error Tech Debt Introduced Prior
    const { auth } = getState();

    return submitEvent({
        calendar: eventDetails,
        auth,
        rejectWithValue,
        isUpdate: true,
        dispatch,
    });
});

// @ts-expect-error Previous TS errors
async function submitEvent({ calendar, auth, rejectWithValue, isUpdate, dispatch, blockInfo = {} }) {
    const scheduledEvent = {
        eventType: calendar.currentEventType,
        name: calendar.currentEventName || null, // Only required if eventType is "Other"
        dateAndTime: calendar.currentEventDateAndTime,
        notes: calendar.currentEventNotes || null,
        slots: calendar.currentEventSlots,
        armsMissions: calendar.currentEventArmsMissions,
        location: calendar.currentEventLocation,
        priority: calendar.priority,
        duplicate: calendar.currentEventDuplicate,
        duplicateDates: calendar.currentEventDuplicationDates,
        ...blockInfo,
    };

    const url = isUpdate ? `${BASE_URL}/v1/events/${calendar.currentEventUniqueIdentifier}` : `${BASE_URL}/v1/events`;
    const toastId = toast.loading('Updating Event');

    try {
        const response = await axios({
            method: 'post',
            url,
            data: scheduledEvent,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        if (!isUpdate) {
            dispatch(clearEvent());
        }

        toast.success('Event update successful.', {
            id: toastId,
        });
        return eventTransformer(response.data);
    } catch (error: any) {
        toast.error('Event update unsuccessful.', {
            id: toastId,
        });
        return rejectWithValue(error.response.data);
    }
}

export const deleteEvent = createAsyncThunk('events/deleteEvent', async (eventId: string, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'DELETE',
            url: `${BASE_URL}/v1/events/${eventId}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        toast.success('Event deleted successful.');
        return response.data;
    } catch (error: any) {
        toast.error('Event delete unsuccessful.');
        return rejectWithValue(error.response.data);
    }
});

export const createEventTemplate = createAsyncThunk('events/createEventTemplate', async (eventData: CalendarEventTemplate, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/events/templates`,
            data: eventData,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Event created successful.');
        return response.data;
    } catch (error: any) {
        toast.error('Event creation unsuccessful.');
        return rejectWithValue(error.response.data);
    }
});

export const getHolidays = createAsyncThunk('events/getHolidays', async (forDates: { start: Date; end: Date }, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/events/holidays?start=${forDates.start.toISOString().split('T')[0]}&end=${forDates.end.toISOString().split('T')[0]}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

//activities
export const getActivities = createAsyncThunk('events/getActivities', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/activity/`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const loadDiscussion = createAsyncThunk('events/loadDiscussion', async (eventId: string, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    if (!eventId) {
        return [];
    }

    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/events/${eventId}/discussion`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

interface SendDiscussionMessageProps {
    eventId: string;
    message: CalendarEventDiscussion;
    mentionUserIds: string[];
}

export const sendDiscussionMessage = createAsyncThunk('events/sendDiscussionMessage', async (props: SendDiscussionMessageProps, { getState, rejectWithValue }) => {
    const { message, mentionUserIds } = props;
    // @ts-ignore
    const { auth, calendar } = getState();
    const eventUniqueIdentifier = calendar.currentEventUniqueIdentifier;

    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/events/${eventUniqueIdentifier}/discussion`,
            data: {
                message: message.message, // Backend expects this to be a string
                mention_user_ids: mentionUserIds,
            },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        toast.success('Message sent.');
        return response.data;
    } catch (error: any) {
        toast.success('An error occurred.');
        return rejectWithValue(error.response.data);
    }
});

export const loadAllDiscussions = createAsyncThunk('events/loadAllDiscussions', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/events/discussion`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteDiscussion = createAsyncThunk('events/deleteDiscussion', async (discussion, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    try {
        const response = await axios({
            method: 'delete',
            // @ts-expect-error Previous TS errors
            url: `${BASE_URL}/v1/events/discussion/${discussion.id}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Delete successful.');
        return response.data;
    } catch (error: any) {
        toast.success('An error occurred.');
        return rejectWithValue(error.response.data);
    }
});

export const clearStartTime = createAsyncThunk('events/clearStartTime', async (_, { getState }) => {
    // @ts-ignore
    const { calendar } = getState();
    const currentEventDateAndTime = calendar.currentEventDateAndTime;
    return {
        ...currentEventDateAndTime,
        startTimeEst: '',
    } as StartDateAndTime;
});

export const clearEvent = createAsyncThunk('events/clearEvent', async (_, { getState }) => {
    // @ts-ignore
    const { calendar } = getState();
    return {
        ...calendar,
    };
});

export const clearEndTime = createAsyncThunk('events/clearEndTime', async (_, { getState }) => {
    // @ts-ignore
    const { calendar } = getState();
    const currentEventDateAndTime = calendar.currentEventDateAndTime;
    return {
        ...currentEventDateAndTime,
    } as StartDateAndTime;
});

export const setCurrentEventDatesAndTimesFromString = createAsyncThunk('events/setCurrentEventDatesAndTimesFromString', async ({ startIso, endIso }: { startIso: string; endIso: string; forceTime?: boolean }) => {
    return {
        eventStartIso: startIso,
        eventEndIso: endIso,
    } as StartDateAndTime;
});

export const setCurrentEventUniqueIdentifier = createAsyncThunk('events/setCurrentEventUniqueIdentifier', async (uniqueIdentifier: string | null) => {
    return uniqueIdentifier;
});

export const setCurrentEventType = createAsyncThunk('events/setCurrentEventType', async (eventType: EventType | null) => {
    return eventType;
});

export const setCurrentEventDateAndTime = createAsyncThunk('events/setCurrentEventDateAndTime', async (startDateAndTime: StartDateAndTime | null) => {
    return startDateAndTime;
});

export const setEventSlots = createAsyncThunk('events/setEventSlots', async (slots: EventSlot[]) => {
    return slots;
});

export const setEventSlotsFromEventType = createAsyncThunk('events/setEventSlotsFromEventType', async (eventType: EventType) => {
    // @ts-expect-error Previous TS errors
    if (!eventType || !eventType.slots || !eventType.slots.length) {
        return [];
    }
    const newSlots = [];
    // @ts-expect-error Previous TS errors
    for (let i = 0; i < eventType.slots.length; i++) {
        // @ts-expect-error Previous TS errors
        const slot = eventType.slots[i];
        if (slot && slot.allowed_qualifications && slot.allowed_qualifications.length) {
            newSlots.push({
                local_id: uuidv4(),
                // @ts-expect-error Previous TS errors
                allowed_qualifications: slot.allowed_qualifications.map((qualification) => {
                    return {
                        value: qualification.id,
                        label: qualification.label,
                    };
                }),
            });
        }
    }
    return newSlots;
});

export const addEventSlot = createAsyncThunk('events/addEventSlot', async (slot: EventSlot) => {
    return { ...slot, local_id: uuidv4() };
});

export const removeSlotAtIndex = createAsyncThunk('events/removeSlotAtIndex', async (slotIndex: number) => {
    return slotIndex;
});

export const updateSlotAtIndex = createAsyncThunk('events/updateSlotAtIndex', async ({ index, slot }: { index: number; slot: EventSlot }) => {
    return { index, slot };
});

export const setFillingEmptySlots = createAsyncThunk('events/setFillingEmptySlots', async ({ newValue }: { newValue: boolean }) => {
    return {
        newValue,
    };
});

export const createSlotFillRequest = createAsyncThunk(
    'events/createSlotFillRequest',
    async (
        {
            // @ts-expect-error Previous TS errors
            recommendationsSessionId,
            eventType,
            dateAndTime,
            officeFilter,
        }: {
            eventId: string;
            eventType: EventType | null;
            dateAndTime: StartDateAndTime | null;
            officeFilter: [];
        },
        { getState, rejectWithValue },
    ) => {
        // @ts-ignore
        const { auth, calendar } = getState();
        try {
            const response = await axios({
                method: 'post',
                url: `${BASE_URL}/v1/events/recommendations/${recommendationsSessionId}`,
                data: {
                    eventType,
                    dateAndTime,
                    armsMissions: calendar.currentEventArmsMissions,
                    slots: calendar.currentEventSlots,
                    office_filter: officeFilter,
                },
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });
            toast.success('Slot flll successful.');
            return response.data;
        } catch (error: any) {
            toast.error('An error occurred.');
            return rejectWithValue(error.response.data);
        }
    },
);

export const retrieveSlotFillRequest = createAsyncThunk('events/retrieveSlotFillRequest', async (slotRequestId, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth, calendar } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/events/recommendations/${slotRequestId}/retrieve`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        const currentEventSlots = calendar.currentEventSlots;
        const currentSlotFillRequest = response.data;
        const currentEventSlotsRecommendations = currentSlotFillRequest.solution_json;
        const updatedEventSlots = [];
        for (let i = 0; i < currentEventSlotsRecommendations.length; i++) {
            const slotRecommendation = currentEventSlotsRecommendations[i];
            updatedEventSlots.push({
                ...currentEventSlots[i],
                recommended_solution: slotRecommendation,
            });
        }

        if (currentEventSlotsRecommendations.length == 0) {
            return {
                currentEventSlots: currentEventSlots,
                currentSlotFillRequest,
            };
        }

        return {
            currentEventSlots: updatedEventSlots,
            currentSlotFillRequest,
        };
    } catch (error: any) {
        console.warn(error);
        return rejectWithValue(error.response.data);
    }
});

export const clearCurrentSlotFillRequest = createAsyncThunk('events/clearCurrentSlotFillRequest', async (_, { getState }) => {
    // @ts-expect-error Previous TS errors
    const { calendar } = getState();
    return {
        // @ts-expect-error Previous TS errors
        currentEventSlots: calendar.currentEventSlots.map((x) => {
            return { ...x, recommended_solution: null };
        }),
        currentSlotFillRequest: null,
    };
});

export const applyCurrentSlotFillRequest = createAsyncThunk('events/applyCurrentSlotFillRequest', async (_, { getState }) => {
    // @ts-expect-error Previous TS errors
    const { calendar } = getState();
    return {
        // @ts-expect-error Previous TS errors
        currentEventSlots: calendar.currentEventSlots.map((x) => {
            return {
                ...x,
                assigned_crew_member: x.recommended_solution?.crew_member ? mapBackendObjectToOption(x.recommended_solution?.crew_member) : null,
            };
        }),
        currentSlotFillRequest: null,
    };
});

export const applySingleSlot = createAsyncThunk('events/applySingleSlot', async (slot: any, { getState }) => {
    // @ts-expect-error Previous TS errors
    const { calendar } = getState();

    return {
        // @ts-expect-error Previous TS errors
        currentEventSlots: calendar.currentEventSlots.map((x) => {
            if (x.local_id === slot.local_id) {
                return {
                    ...x,
                    assigned_crew_member: x.recommended_solution?.crew_member ? mapBackendObjectToOption(x.recommended_solution?.crew_member) : null,
                };
            } else {
                return {
                    ...x,
                };
            }
        }),
    };
});

export const changedEventName = createAsyncThunk('events/changedEventName', async (newName: string) => {
    return newName;
});

export const changedEventNotes = createAsyncThunk('events/changedEventNotes', async (newNotes: string) => {
    return newNotes;
});

export const setCurrentEventLookbackEventTypes = createAsyncThunk('events/setCurrentEventLookbackEventTypes', async (updatedTypes: Array<LookbackEventType>) => {
    return updatedTypes;
});

export const setCurrentEventLocation = createAsyncThunk('events/setCurrentEventLocation', async (updatedLocation: Location | null) => {
    return updatedLocation;
});
export const setCurrentEventDuplication = createAsyncThunk('events/setCurrentEventDuplication', async ({ duplicate, duplicationDates }: { duplicate: Duplicate; duplicationDates: string[] }) => {
    return { duplicate, duplicationDates };
});

export const setCurrentEventWebsocketId = createAsyncThunk('events/setCurrentEventWebsocketId', async (updatedWebsocketId: string) => {
    return updatedWebsocketId;
});

export const addDiscussion = createAsyncThunk('events/addDicussion', async (discussion: any) => {
    return discussion;
});

export const setCurrentEventPriority = createAsyncThunk('events/setCurrentEventPriority', async (priority: number) => {
    return priority;
});

export const updateDiscussionAsRead = createAsyncThunk('events/updateDiscussionAsRead', async (discussion: any) => {
    return discussion;
});

export const updateDiscussionAsUnread = createAsyncThunk('events/updateDiscussionAsUnread', async (discussion: any) => {
    return discussion;
});

export const clearCurrentEvents = createAsyncThunk('events/clearCurrentEvents', async () => {
    return true;
});

export const conflictingCrewMembers = createAsyncThunk(
    'events/conflicting_events',
    async (
        {
            startDate,
            endDate,
            eventId,
        }: {
            startDate: string;
            endDate: string;
            eventId?: string;
        },
        { getState, rejectWithValue },
    ) => {
        // @ts-ignore
        const { auth, users } = getState();
        const crewMembers = users.users.map((crewMember: any) => crewMember.id);

        try {
            const { data } = await axios.get(`${BASE_URL}/v1/users/conflicting_events?crew_members=${encodeURIComponent(`${crewMembers.join(', ')}`)}`, {
                params: {
                    start_date: startDate,
                    end_date: endDate,
                },
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });

            const crewIdToConflicts: any = {};

            data.forEach((conflict: any) => {
                if (eventId && (eventId === conflict.unique_identifier || (eventId === conflict.id && conflict.event_type !== 'Commitment'))) {
                    return;
                }

                if (!crewIdToConflicts[conflict.crew_member_id] && eventId !== conflict.unique_identifier) {
                    crewIdToConflicts[conflict.crew_member_id] = [];
                }

                if (!crewIdToConflicts[conflict.crew_member_id].find((event: any) => event.id === conflict.id) && eventId !== conflict.unique_identifier) {
                    crewIdToConflicts[conflict.crew_member_id].push(conflict);
                }
            });

            return crewIdToConflicts;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

const eventSlice = createSlice({
    name: 'calendar',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchEvents.fulfilled]: (state, action) => {
            state.events = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchEvents.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchEvents.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error fetching events.';
        },
        // @ts-ignore
        [fetchEventsWithFilters.fulfilled]: (state, action) => {
            state.events = action.payload.data;
            state.filters = action.payload.filters;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchEventsWithFilters.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchEventsWithFilters.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error fetching events by user.';
        },
        // @ts-ignore
        [createEvent.fulfilled]: (state, action) => {
            // TODO: update specific event
            // state.events = action.payload;
            state.events = [...state.events, action.payload];
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [conflictingCrewMembers.fulfilled]: (state, action) => {
            state.conflicts.data = action.payload;
            state.conflicts.loading = false;
        },
        // @ts-ignore
        [conflictingCrewMembers.pending]: (state) => {
            state.conflicts.loading = true;
        },
        // @ts-ignore
        [conflictingCrewMembers.rejected]: (state, action) => {
            state.conflicts.error = action.payload;
            state.conflicts.loading = false;
        },
        // @ts-ignore
        [createEvent.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [createEvent.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error creating event.';
        },
        // @ts-ignore
        [createEventTemplate.fulfilled]: (state, action) => {
            state.eventTemplates = [action.payload, ...state.eventTemplates];
            state.error = '';
            state.isSavingEventTemplate = false;
        },
        // @ts-ignore
        [createEventTemplate.pending]: (state) => {
            state.isSavingEventTemplate = true;
        },
        // @ts-ignore
        [createEventTemplate.rejected]: (state) => {
            state.isSavingEventTemplate = false;
            state.error = 'Error creating event template.';
        },
        // @ts-ignore
        [updateEvent.fulfilled]: (state, action) => {
            const index = state.events.findIndex((events: CalendarEvent) => events.id === action.payload.id);
            state.events[index] = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [updateEvent.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [updateEvent.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error creating event.';
        },

        // @ts-ignore
        [clearEvent.fulfilled]: (state) => {
            state.currentEventDateAndTime = null;
            state.currentEventType = null;
            state.currentEventName = null;
            state.currentEventNotes = null;
            state.currentEventDateAndTime = null;
            state.currentEventSlots = [];
            state.currentEventArmsMissions = [];
            state.currentEventLocation = null;
            state.currentEventDuplicate = Duplicate.no;
            state.currentEventDuplicationDates = [];
            state.priority = 4;
        },
        // @ts-ignore
        [clearCurrentEvents.fulfilled]: (state) => {
            state.currentEventSlots = [];
        },

        // @ts-ignore
        [deleteEvent.fulfilled]: (state, action) => {
            state.error = '';
            state.isLoading = false;
            state.events = state.events.filter(
                // @ts-expect-error Previous TS errors
                (event) => event.unique_identifier !== action.meta.arg,
            );
        },
        // @ts-ignore
        [deleteEvent.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [deleteEvent.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error deleting event.';
        },
        // @ts-ignore
        [getHolidays.fulfilled]: (state, action) => {
            state.holidays = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [getHolidays.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [getHolidays.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error fetching holidays.';
        },
        //activity
        // @ts-ignore
        [getActivities.fulfilled]: (state) => {
            console.warn('Need to update activities');
            // state.events = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [getActivities.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [getActivities.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error fetching activities.';
        },
        // @ts-ignore
        [loadDiscussion.pending]: (state) => {
            state.loadingDiscussions = true;
            state.discussions = [];
        },
        // @ts-ignore
        [loadDiscussion.fulfilled]: (state, action) => {
            state.loadingDiscussions = false;
            state.discussions = action.payload;
        },
        // @ts-ignore
        [sendDiscussionMessage.fulfilled]: (state, action) => {
            const newDiscussion = {
                ...action.payload,
                created_at: new Date(),
            };
            state.discussions = [...state.discussions, newDiscussion];
            state.allDiscussions = [newDiscussion, ...state.allDiscussions];
        },
        // @ts-ignore
        [loadAllDiscussions.fulfilled]: (state, action) => {
            state.allDiscussions = action.payload;
            state.isLoadingAllDiscussions = false;
        },
        // @ts-ignore
        [loadAllDiscussions.rejected]: (state) => {
            state.isLoadingAllDiscussions = false;
        },
        // @ts-ignore
        [loadAllDiscussions.pending]: (state) => {
            state.isLoadingAllDiscussions = true;
        },
        // @ts-ignore
        [getRainbowEvents.fulfilled]: (state, action) => {
            state.rainbow_events = action.payload;
            state.rainbowError = '';
            state.isLoadingRainbow = false;
        },
        // @ts-ignore
        [getRainbowEvents.pending]: (state) => {
            state.isLoadingRainbow = true;
        },
        // @ts-ignore
        [getRainbowEvents.rejected]: (state) => {
            state.isLoadingRainbow = false;
            state.rainbowError = 'Error fetching events.';
        },
        // @ts-ignore
        [deleteDiscussion.fulfilled]: (state, action) => {
            state.allDiscussions = state.allDiscussions.filter(
                // @ts-expect-error Previous TS errors
                (discussion) => discussion.id !== action.meta.arg.id,
            );
        },
        // @ts-ignore
        [clearStartTime.fulfilled]: (state, action) => {
            state.currentEventDateAndTime = action.payload;
        },
        // @ts-ignore
        [clearEndTime.fulfilled]: (state, action) => {
            state.currentEventDateAndTime = action.payload;
        },
        // @ts-ignore
        [setCurrentEventDatesAndTimesFromString.fulfilled]: (state, action) => {
            state.currentEventDateAndTime = action.payload;
        },
        // @ts-ignore
        [setCurrentEventUniqueIdentifier.fulfilled]: (state, action) => {
            state.currentEventUniqueIdentifier = action.payload;
        },
        // @ts-ignore
        [setCurrentEventType.fulfilled]: (state, action) => {
            state.currentEventType = action.payload;
        },
        // @ts-ignore
        [setCurrentEventDateAndTime.fulfilled]: (state, action) => {
            state.currentEventDateAndTime = action.payload;
        },
        // @ts-ignore
        [setEventSlots.fulfilled]: (state, action) => {
            state.currentEventSlots = action.payload;
        },
        // @ts-ignore
        [setEventSlotsFromEventType.fulfilled]: (state, action) => {
            state.currentEventSlots = action.payload;
        },
        // @ts-ignore
        [addEventSlot.fulfilled]: (state, action) => {
            // We add it to the top because the button is at the top and we want to show the most recent first
            state.currentEventSlots = [action.payload, ...state.currentEventSlots];
        },
        // @ts-ignore
        [removeSlotAtIndex.fulfilled]: (state, action) => {
            state.currentEventSlots = state.currentEventSlots.filter((slot: any, index: number) => index !== action.payload);
        },
        // @ts-ignore
        [updateSlotAtIndex.fulfilled]: (state, action) => {
            state.currentEventSlots[action.payload.index] = action.payload.slot as EventSlot;
        },
        // @ts-ignore
        [retrieveSlotFillRequest.fulfilled]: (state, action) => {
            state.currentEventSlots = action.payload.currentEventSlots;
            state.currentSlotFillRequest = action.payload.currentSlotFillRequest;
            state.fillingEmptySlots = false;
        },
        // @ts-ignore
        [clearCurrentSlotFillRequest.fulfilled]: (state, action) => {
            state.currentEventSlots = action.payload.currentEventSlots;
            state.currentSlotFillRequest = action.payload.currentSlotFillRequest;
            state.fillingEmptySlots = false;
            state.slotFillError = null;
        },
        // @ts-ignore
        [applyCurrentSlotFillRequest.fulfilled]: (state, action) => {
            state.currentEventSlots = action.payload.currentEventSlots;
            state.currentSlotFillRequest = action.payload.currentSlotFillRequest;
        },
        // @ts-ignore
        [applySingleSlot.fulfilled]: (state, action) => {
            state.currentEventSlots = action.payload.currentEventSlots;
        },
        // @ts-ignore
        [setFillingEmptySlots.pending]: (state, action) => {
            state.slotFillError = null;
        },
        // @ts-ignore
        [setFillingEmptySlots.fulfilled]: (state, action) => {
            state.slotFillError = null;
            state.fillingEmptySlots = action.payload.newValue;
        },
        // @ts-ignore
        [createSlotFillRequest.rejected]: (state, action) => {
            // TODO: Show an error
            state.fillingEmptySlots = false;
            state.slotFillError = action.payload?.error || 'Unable to generate recommendations';
        },
        // @ts-ignore
        [changedEventName.fulfilled]: (state, action) => {
            state.currentEventName = action.payload;
        },
        // @ts-ignore
        [changedEventNotes.fulfilled]: (state, action) => {
            state.currentEventNotes = action.payload;
        },
        // @ts-ignore
        [setCurrentEventLookbackEventTypes.fulfilled]: (state, action) => {
            state.currentEventArmsMissions = action.payload;
        },
        // @ts-ignore
        [setCurrentEventLocation.fulfilled]: (state, action) => {
            state.currentEventLocation = action.payload;
        },
        // @ts-ignore
        [setCurrentEventDuplication.fulfilled]: (state, action) => {
            state.currentEventDuplicate = action.payload.duplicate;
            state.currentEventDuplicationDates = action.payload.duplicationDates;
        },
        // @ts-ignore
        [setCurrentEventWebsocketId.fulfilled]: (state, action) => {
            state.currentEventWebsocketId = action.payload;
        },

        // @ts-ignore
        [addDiscussion.fulfilled]: (state, action) => {
            state.discussions = [action.payload, ...state.discussions];
            state.allDiscussions = [action.payload, ...state.allDiscussions];
        },

        //@ts-ignore
        [setCurrentEventPriority.fulfilled]: (state, action) => {
            state.priority = action.payload;
        },

        //@ts-ignore
        [updateDiscussionAsRead.fulfilled]: (state, action) => {
            state.allDiscussions = state.allDiscussions.map((discussion: any) => {
                if (discussion.id === action.meta.arg.id) return { ...discussion, read: true };

                return discussion;
            });
        },
        //@ts-ignore
        [updateDiscussionAsUnread.fulfilled]: (state, action) => {
            state.allDiscussions = state.allDiscussions.map((discussion: any) => {
                if (discussion.id === action.meta.arg.id) return { ...discussion, read: false };

                return discussion;
            });
        },
    },
});

export default eventSlice.reducer;
