import { createTheme } from '@mui/material/styles';

export const themeOptions = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#2563eb',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            default: '#2b3763',
            paper: '#2f3a63',
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                root: {
                    backgroundImage: 'none',
                },
            },
        },
    },
});
