import React from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import styled from 'styled-components';
import ErrorBoundary from './components/ErrorBoundary';
import { AppRoutes } from './pages/Routes';
import { store } from './store';
import { Theme } from './utilities/Theme';
import { QueryClient, QueryClientProvider, useQuery } from '@tanstack/react-query';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { themeOptions } from './utilities/MuiTheme';

function App() {
    const queryClient = new QueryClient();
    return (
        <QueryClientProvider client={queryClient}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <Provider store={store}>
                    <ThemeProvider theme={Theme}>
                        <ErrorBoundary>
                            <StyledAppContainer>
                                <BrowserRouter>
                                    <AppRoutes />
                                </BrowserRouter>
                            </StyledAppContainer>
                        </ErrorBoundary>
                    </ThemeProvider>
                    <Toaster
                        toastOptions={{
                            style: {
                                minWidth: '400px',
                            },
                        }}
                    />
                </Provider>
            </LocalizationProvider>
        </QueryClientProvider>
    );
}

const StyledAppContainer = styled.div`
    font-family: Manrope;

    * {
        ::-webkit-scrollbar {
            width: 12px;
            height: 12px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
            background: ${(props) => props.theme.colors.gray.ten};
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
            background: ${(props) => props.theme.colors.gray.six};
            border-radius: ${(props) => props.theme.borderRadius.two};
        }
    }
`;

export default App;
