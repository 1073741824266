import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Announcement } from '../static/interfaces/announcement';
import { BASE_URL } from '../utilities/Api';
import { TRootState } from './index';
import { useFileStorage } from './zustand/files';

interface InitialState {
    results: Array<Announcement>;
    isLoading: boolean;
    error: string;
}

let initialState: InitialState = {
    results: [],
    isLoading: false,
    error: '',
};

export const fetchAnnouncements = createAsyncThunk('announcements/fetchAnnouncements', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/announcements`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createAnnouncement = createAsyncThunk('announcements/createAnnouncement', async ({ announcement, files, ...props }: any, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    const formData = new FormData();

    if (files.length > 0) {
        formData.append('image', files[0]);
    }

    formData.append('title', announcement.title);
    formData.append('content', announcement.content);

    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/announcements`,
            data: formData,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        toast.success('Announcement created.');
        return response.data;
    } catch (error: any) {
        toast.error('An error occurred.');
        return rejectWithValue(error.response.data);
    }
});

export const updateAnnouncement = createAsyncThunk('announcements/updateAnnouncement', async ({ announcement, files, ...props }: any, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    const formData = new FormData();

    if (files.length > 0) {
        formData.append('image', files[0]);
    }

    formData.append('title', announcement.title);
    formData.append('content', announcement.content);

    try {
        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/v1/announcements/${announcement.id}`,
            data: formData,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        toast.success('Announcement updated.');
        return response.data;
    } catch (error: any) {
        toast.error('An error occurred.');
        return rejectWithValue(error.response.data);
    }
});

export const deleteAnnouncement = createAsyncThunk('announcements/deleteAnnouncement', async (announcement: Announcement, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'delete',
            url: `${BASE_URL}/v1/announcements/${announcement.id}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Announcement deleted.');
        return response.data;
    } catch (error: any) {
        toast.error('An error occurred.');
        return rejectWithValue(error.response.data);
    }
});

const announcementSlice = createSlice({
    name: 'announcements',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchAnnouncements.fulfilled]: (state, action) => {
            state.results = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchAnnouncements.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchAnnouncements.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error fetching announcements.';
        },
        // @ts-ignore
        [createAnnouncement.fulfilled]: (state, action) => {
            state.results = [action.payload, ...state.results];
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [createAnnouncement.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [createAnnouncement.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error creating announcement.';
        },
        // @ts-ignore
        [updateAnnouncement.fulfilled]: (state, action) => {
            // @ts-expect-error Previous TS Error
            state.results = state.results.map((announcement) => (announcement.id === action.payload.id ? action.payload : announcement));
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [deleteAnnouncement.fulfilled]: (state, action) => {
            state.results = state.results.filter(
                // @ts-expect-error Previous TS Error
                (announcement) => announcement.id !== action.meta.arg.id,
            );
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [deleteAnnouncement.pending]: (state) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [deleteAnnouncement.rejected]: (state) => {
            state.isLoading = false;
            state.error = 'Error creating announcement.';
        },
    },
});

export default announcementSlice.reducer;
