import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utilities/Api';

interface LookbackType {}

interface LookbackRequirement {}

interface InitialState {
    lookback_event_types: Array<LookbackType>;
    lookback_requirements: Array<LookbackRequirement>;
    isLoading: boolean;
    error?: string;
}

let initialState: InitialState = {
    lookback_event_types: [],
    lookback_requirements: [],
    isLoading: true,
    error: undefined,
};

interface FetchThreeMonthLookbackProps {
    isoDate: string;
    selectedCrewMemberId: string;
}

export const fetchThreeMonthLookback = createAsyncThunk(
    'threeMonthLookback/fetchThreeMonthLookback',
    async (props: FetchThreeMonthLookbackProps, { getState, rejectWithValue }) => {
        const { isoDate, selectedCrewMemberId } = props;
        // @ts-ignore
        const { auth } = getState();

        try {
            const response = await axios({
                method: 'get',
                url: `${BASE_URL}/v1/three_month_lookback?date=${isoDate}&crewMemberId=${selectedCrewMemberId}`,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });

            let resultingRequirements = [];

            for (let i = 0; i < response.data.length; i++) {
                const resultsByQualificationId = response.data[i].results_by_qualification_id;
                const lookbackRequirements = response.data[i].lookback_requirements;
                for (let j = 0; j < lookbackRequirements.length; j++) {
                    const lookbackRequirement = lookbackRequirements[j];
                    if (i === 0) {
                        resultingRequirements.push({
                            qualifications: lookbackRequirement.qualifications,
                            qualificationsText: lookbackRequirement.qualifications_text,
                            resultsByQualificationId: resultsByQualificationId || null,
                            items: [],
                        });
                    }
                    resultingRequirements[j].items.push({
                        ...lookbackRequirement,
                    });
                }
            }

            return {
                eventTypes: response.data,
                requirements: resultingRequirements,
            };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

const threeMonthLookbackSlice = createSlice({
    name: 'three_month_lookback',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchThreeMonthLookback.fulfilled]: (state, action) => {
            state.lookback_event_types = action.payload.eventTypes;
            state.lookback_requirements = action.payload.requirements;
            state.isLoading = false;
            state.error = undefined;
        },
        // @ts-ignore
        [fetchThreeMonthLookback.pending]: (state, action) => {
            state.isLoading = true;
            state.error = undefined;
        },
        // @ts-ignore
        [fetchThreeMonthLookback.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error fetching three month lookback.';
        },
    },
});

export default threeMonthLookbackSlice.reducer;
