export const mapBackendObjectToOption = (item) => {
    if (!item) return null;

    return {
        ...item,
        value: item.id || item.value,
        label: item.label || item.name,
    };
};

export const mapCrewToOption = (item) => {
    return {
        ...item,
        value: item.id || item.value,
        label: `${item.last_name}, ${item.first_name}, ${item.rank_text}`,
    };
};

export const availableEventTypesToOptions = (availableEventTypes) => {
    return [
        {
            label: '(Select an Event Type)',
            value: null,
        },
        ...availableEventTypes.map(mapBackendObjectToOption).sort((a, b) => (b.label === 'Other' || a?.label > b?.label ? 1 : -1)),
    ];
};
