import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Upload } from '../static/interfaces/upload';
import { BASE_URL } from '../utilities/Api';

interface InitialState {
    result: Upload | null;
    isLoading: boolean;
    error: string;
}

let initialState: InitialState = {
    result: null,
    isLoading: false,
    error: '',
};

export const uploadFile = createAsyncThunk(
    'uploads/uploadFile',
    async (file: string | Blob, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();

        let formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios({
                method: 'POST',
                url: `${BASE_URL}/v1/uploads/`,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                    'Content-Type': 'multipart/form-data',
                },
                data: formData,
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

const uploadsSlice = createSlice({
    name: 'uploads',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [uploadFile.fulfilled]: (state, action) => {
            state.results = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [uploadFile.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [uploadFile.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error creating announcement.';
        },
    },
});

export default uploadsSlice.reducer;
