import Loadable from '@loadable/component';
import { CssBaseline, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useNavigate, useParams } from 'react-router-dom';
import { SESSION_STORAGE_ORIGINAL_URL } from '../constants/storage';
import { TRootState } from '../store';
import { components } from 'react-select';

const LinearLoader = () => (
    <Box sx={{ width: '100%' }}>
        <LinearProgress />
    </Box>
);

const AsyncDashboard = Loadable(() => import('./Dashboard'), {
    resolveComponent: (components) => components.Dashboard,
    fallback: <LinearLoader />,
});

const AsyncWeeklyCalendar = Loadable(() => import('./WeeklyCalendarTable'), {
    resolveComponent: (components) => components.default,
    fallback: <LinearLoader />,
});

const AsyncAugmentation = Loadable(() => import('../elements/Augmentation'), {
    resolveComponent: (components) => components.default,
    fallback: <LinearLoader />,
});

const AsyncDashboardHome = Loadable(() => import('../elements/DashboardHome'), {
    resolveComponent: (components) => components.DashboardHome,
    fallback: <LinearLoader />,
});

const AsyncLogin = Loadable(() => import('./Login'), {
    resolveComponent: (components) => components.Login,
    fallback: <LinearLoader />,
});

const AsyncCalendar = Loadable(() => import('../elements/Calendar'), {
    resolveComponent: (components) => components.Calendar,
    fallback: <LinearLoader />,
});

const AsyncCalendarLong = Loadable(() => import('../elements/Calendar'), {
    resolveComponent: (components) => components.Calendar,
    fallback: <LinearLoader />,
});

const AsyncRainbowCalendar = Loadable(() => import('../elements/RainbowCalendar/RainbowCalendar'), {
    resolveComponent: (components) => components.MemoizeRainbowCalendar,
    fallback: <LinearLoader />,
});

const AsyncEventsTemplate = Loadable(() => import('../routes/calendar/EventTemplates'), {
    fallback: <LinearLoader />,
});

const AsyncSchedule = Loadable(() => import('../elements/Schedule'), {
    resolveComponent: (components) => components.Schedule,
    fallback: <LinearLoader />,
});

const AsyncCrew = Loadable(() => import('../elements/Crew'), {
    resolveComponent: (components) => components.Crew,
    fallback: <LinearLoader />,
});

const AsyncCommittments = Loadable(() => import('../elements/Commitments'), {
    resolveComponent: (components) => components.Commitments,
    fallback: <LinearLoader />,
});

const AsyncApprovals = Loadable(() => import('../elements/Approvals'), {
    resolveComponent: (components) => components.Approvals,
    fallback: <LinearLoader />,
});

const AsyncApprovalsWorflow = Loadable(() => import('../elements/approval_workflow'), {
    resolveComponent: (components) => components.ApprovalWorkflow,
    fallback: <LinearLoader />,
});

const AsyncDiscussions = Loadable(() => import('../elements/Discussions'), {
    resolveComponent: (components) => components.Discussions,
    fallback: <LinearLoader />,
});

const AsyncThreeMonthLookBack = Loadable(() => import('../elements/3_month_lookback'), {
    resolveComponent: (components) => components.ThreeMonthLookback,
    fallback: <LinearLoader />,
});

const AsyncRAP = Loadable(() => import('../elements/RAP'), {
    resolveComponent: (components) => components.RAP,
    fallback: <LinearLoader />,
});

const AsyncEventNotifications = Loadable(() => import('./Notifications'), {
    resolveComponent: (components) => components.default,
    fallback: <LinearLoader />,
});

const Async404 = Loadable(() => import('./NotFound'), {
    resolveComponent: (components) => components.NotFound,
    fallback: <LinearLoader />,
});

const AsyncCrewReports = Loadable(() => import('./CrewReports'), {
    resolveComponent: (components) => components.default,
    fallback: <LinearLoader />,
});

export function AppRoutes() {
    const [isAppLoaded, setAppLoaded] = useState(false);
    const isMobile = useMediaQuery('(max-width:600px)');
    const authData = useSelector((state: TRootState) => state.auth);
    const navigate = useNavigate();

    useEffect(() => {
        setAppLoaded(true);
    }, []);

    useEffect(() => {
        if (!authData.access_token) {
            const pathName = window.sessionStorage.getItem(SESSION_STORAGE_ORIGINAL_URL);

            if (!pathName) {
                window.sessionStorage.setItem(SESSION_STORAGE_ORIGINAL_URL, `${location.pathname}${location.search}`);
            }

            navigate('/login');
        }
    }, [authData, navigate]);

    if (!isAppLoaded) {
        return <LinearLoader />;
    }

    return (
        <>
            <CssBaseline />
            <Routes>
                <Route path="/login" element={<AsyncLogin />} />
                <Route path="/" element={<AsyncDashboard />}>
                    <Route path="/" element={<AsyncDashboardHome />} />
                    <Route path="/calendar" element={<AsyncCalendar view={isMobile ? 'timeGridDay' : 'timeGridWeek'} />} />
                    <Route path="/longrange" element={<AsyncCalendarLong isLongRange />} />
                    <Route path="/rainbow" element={<AsyncRainbowCalendar />} />
                    <Route path="/templates" element={<AsyncEventsTemplate />} />
                    <Route path="/schedule" element={<AsyncSchedule />} />
                    <Route path="/settings" element={<AsyncDashboardHome />} />
                    <Route path="/crew" element={<AsyncCrew />} />
                    <Route path="/commitments" element={<AsyncCommittments />} />
                    <Route path="/approval_workflow" element={<AsyncApprovalsWorflow />} />
                    <Route path="/3_month_lookback" element={<AsyncThreeMonthLookBack />} />
                    <Route path="/rap" element={<AsyncRAP />} />
                    <Route path="/discussions" element={<AsyncDiscussions />} />
                    <Route path="/approvals" element={<AsyncApprovals />} />
                    <Route path="/event_notifications" element={<AsyncEventNotifications />} />
                    <Route path="/augmentations" element={<AsyncAugmentation />} />
                    <Route path="/weekly-calendar" element={<AsyncWeeklyCalendar />} />
                    <Route path="/shifts-by_crew" element={<AsyncCrewReports />} />
                </Route>
                <Route path="*" element={<Async404 />} />
            </Routes>
        </>
    );
}
