import { configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';
import { combineReducers } from 'redux';
import exampleReducer from './exampleSlice';
import eventsReducer from './eventsSlice';
import authReducer from './authSlice';
import locationReducer from './locationSlice';
import scheduleReducer from './scheduleSlice';
import usersSlice from './usersSlice';
import announcementsSlice from './announcementsSlice';
import uploadsSlice from './uploadsSlice';
import notificationsSlice from './notificationsSlice';
import approvalWorkflowSlice from './approvalWorkflowSlice';
import threeMonthLookbackSlice from './threeMonthLookbackSlice';
import rapSlice from './rapSlice';
import discussionsSlice from './discussionsSlice';
import commitmentsSlice from './commitmentsSlice';

let rootReducer = combineReducers({
    example: exampleReducer,
    calendar: eventsReducer,
    auth: authReducer,
    locations: locationReducer,
    schedules: scheduleReducer,
    users: usersSlice,
    announcements: announcementsSlice,
    uploads: uploadsSlice,
    notifications: notificationsSlice,
    approvalWorkflow: approvalWorkflowSlice,
    threeMonthLookback: threeMonthLookbackSlice,
    rap: rapSlice,
    discussions: discussionsSlice,
    commitments: commitmentsSlice,
});

export type TAppDispatch = typeof store.dispatch;
export type TRootState = ReturnType<typeof rootReducer>;
export type TAppThunk = ThunkAction<void, TRootState, unknown, Action<string>>;

export const store = configureStore({
    reducer: rootReducer,
});
