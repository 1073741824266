import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utilities/Api';

interface InitialState {
    scheduleRequests: Array<any>;
    isLoading: boolean;
    error: string;
}

interface CreateScheduleData {
    start: Date;
    end: Date;
}

let initialState: InitialState = {
    scheduleRequests: [],
    isLoading: false,
    error: '',
};

export const fetchSchedules = createAsyncThunk(
    'schedules/fetchSchedules',
    async (_, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();

        try {
            const response = await axios({
                method: 'get',
                url: `${BASE_URL}/v1/schedules/`,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const fetchSchedule = createAsyncThunk(
    'schedules/fetchSchedule',
    async (scheduleId, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();

        try {
            const response = await axios({
                method: 'get',
                url: `${BASE_URL}/v1/schedules/${scheduleId}/`,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const createSchedule = createAsyncThunk(
    'schedules/createSchedule',
    async (data: CreateScheduleData, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();

        try {
            const response = await axios({
                method: 'post',
                url: `${BASE_URL}/v1/schedules/`,
                data: data,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

const scheduleSlice = createSlice({
    name: 'schedules',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchSchedules.fulfilled]: (state, action) => {
            state.scheduleRequests = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchSchedules.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchSchedules.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error fetching schedules.';
        },
        // @ts-ignore
        [createSchedule.fulfilled]: (state, action) => {
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [createSchedule.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [createSchedule.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error creating schedule.';
        },
    },
});

export default scheduleSlice.reducer;
