import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import { TRootState, TAppThunk } from "./index";

interface IInitialState {
    count: number;
}

interface IAddOnePayload {
    count: number;
}

let initialState: IInitialState = {
    count: 0,
};

const exampleSlice = createSlice({
    name: 'counter',
    initialState: initialState,
    reducers: {
        addOne(state, action: PayloadAction<IAddOnePayload>) {
            const { count } = action.payload;
            state.count = count + 1;
        },
    },
});

export const { addOne } = exampleSlice.actions;

export default exampleSlice.reducer;

// Thunk example case
// Thunks go below Slice

// export const fetchSomething = (
//     arg1: string,
//     arg2: string
//   ): TAppThunk => async dispatch => {
//     try {
//       dispatch(isLoading())
//       const data = await getData(arg1, arg2)
//       dispatch(action1Here(repoDetails))
//     } catch (err) {
//       dispatch(action1FailedHere(err.toString()))
//     }
//   }
