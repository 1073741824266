import { Alert, Container } from '@mui/material';
import React from 'react';

class ErrorBoundary extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch() {
        // Log errors to 3rd party service. TVD
    }

    render() {
        if (this.state.hasError) {
            return (
                <>
                    <br />
                    <br />
                    <Container maxWidth="md">
                        <Alert severity="error">An error occurred. Please reach out to a system admin and refresh the page.</Alert>
                    </Container>
                </>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
