import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { themeOptions } from './utilities/MuiTheme';
import GlobalStyles from '@mui/material/GlobalStyles';

ReactDOM.render(
    <React.StrictMode>
        <GlobalStyles
            styles={{
                paper: {
                    backgroundImage: 'none',
                },
            }}
        />
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeOptions}>
                <CssBaseline enableColorScheme />
                <App />
            </ThemeProvider>
        </StyledEngineProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.lg))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
