import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Location } from '../static/interfaces/general';
import { BASE_URL } from '../utilities/Api';

interface InitialState {
    locations: Array<Location>;
    error: string;
    isLoading: boolean;
}

let initialState: InitialState = {
    locations: [],
    isLoading: false,
    error: '',
};

export const fetchLocations = createAsyncThunk(
    'locations/fetchLocations',
    async (_, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();
        try {
            const response = await axios({
                method: 'get',
                url: `${BASE_URL}/v1/locations`,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const createLocation = createAsyncThunk(
    'locations/createLocation',
    async (locationData: Location, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();
        try {
            const response = await axios({
                method: 'post',
                url: `${BASE_URL}/v1/locations`,
                data: locationData,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });

            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const updateLocation = createAsyncThunk(
    'location/updateLocation',
    async (locationData: Location, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();
        try {
            const response = await axios({
                method: 'put',
                url: `${BASE_URL}/v1/locations/${locationData.id}`,
                data: locationData,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

export const deleteLocation = createAsyncThunk(
    'location/deleteLocation',
    async (locationId: string, { getState, rejectWithValue }) => {
        // @ts-ignore
        const { auth } = getState();
        // TODO: remove temp cleaned data
        try {
            const response = await axios({
                method: 'delete',
                url: `${BASE_URL}/v1/locations/${locationId}`,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

const locationSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchLocations.fulfilled]: (state, action) => {
            state.locations = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchLocations.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchLocations.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error fetching locations.';
        },
        // @ts-ignore
        [createLocation.fulfilled]: (state, action) => {
            state.locations = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [createLocation.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [createLocation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error creating locations.';
        },
        // @ts-ignore
        [updateLocation.fulfilled]: (state, action) => {
            state.locations = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [updateLocation.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [updateLocation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error updating location.';
        },
        // @ts-ignore
        [deleteLocation.fulfilled]: (state, action) => {
            state.locations = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [deleteLocation.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [deleteLocation.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error deleting location.';
        },
    },
});

export default locationSlice.reducer;
