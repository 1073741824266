import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_URL } from '../utilities/Api';

interface InitialState {
    selected_discussion: any;
    replies: [];
}
let initialState: InitialState = {
    replies: [],
    selected_discussion: {},
};

export const submitReply = createAsyncThunk('discussions/createReply', async (reply: any, { getState }) => {
    try {
        // @ts-ignore
        const { auth } = getState();

        const response = await axios({
            method: 'PUT',
            url: `${BASE_URL}/v1/discussions/${reply.discussion_id}/replies`,
            data: {
                ...reply,
            },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Reply sent.');
        return response.data;
    } catch (e) {
        toast.error('An error occurred.');
    }
});

export const setSelectedDiscussion = createAsyncThunk('discussions/setSelectedDiscussion', async (discussion: any) => {
    return discussion;
});

export const loadDiscussionReplies = createAsyncThunk('discussions/loadDiscussionReplies', async (discussion_id: number, { getState }) => {
    // @ts-ignore
    const { auth } = getState();

    const response = await axios({
        method: 'GET',
        url: `${BASE_URL}/v1/discussions/${discussion_id}/replies`,
        headers: {
            Authorization: `Bearer ${auth.access_token}`,
        },
    });

    return response.data;
});

export const deleteReply = createAsyncThunk('discussions/deleteReply', async (reply: any, { getState }) => {
    try {
        // @ts-ignore
        const { auth } = getState();

        await axios({
            method: 'DELETE',
            url: `${BASE_URL}/v1/discussion_replies/${reply.id}`,
            data: {
                ...reply,
            },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Reply deleted.');
        return reply;
    } catch (e) {
        toast.error('An error occurred.');
    }
});

export const createDiscussionWithoutEvent = createAsyncThunk('discussions/createDiscussionWithoutEvent', async (discussion: any, { getState }) => {
    try {
        // @ts-ignore
        const { auth } = getState();

        const response = await axios({
            method: 'POST',
            url: `${BASE_URL}/v1/discussions`,
            data: {
                ...discussion,
            },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Discussion created.');
        return response.data;
    } catch (e) {
        toast.error('An error occurred.');
    }
});

const discussionSlice = createSlice({
    name: 'discussionSlice',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [submitReply.fulfilled]: (state, action) => {
            state.replies.push(action.payload);
        },
        // @ts-ignore
        [setSelectedDiscussion.fulfilled]: (state, action) => {
            state.selected_discussion = action.payload;
        },

        // @ts-ignore
        [loadDiscussionReplies.fulfilled]: (state, action) => {
            state.replies = action.payload;
        },

        // @ts-ignore
        [deleteReply.fulfilled]: (state, action) => {
            state.replies = state.replies.filter((reply: any) => reply.id !== action.payload.id);
        },

        // @ts-ignore
        [createDiscussionWithoutEvent.fulfilled]: (state, action) => {
            state.selected_discussion = action.payload;
        },
    },
});

export default discussionSlice.reducer;
