/* eslint-disable no-unused-vars */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Person } from '../static/interfaces/users';
import { BASE_URL } from '../utilities/Api';

interface InitialState {
    users: Array<Person>;
    usersByLocation: Array<Person>;
    error: string;
    isLoading: boolean;
    me: Person;
}

let initialState: InitialState = {
    users: [],
    usersByLocation: [],
    isLoading: false,
    error: '',
    // @ts-ignore
    me: {
        id: '',
        email: '',
        is_superuser: false,
        avatar_url: '',
        first_name: '',
        last_name: '',
        call_sign: '',
        rank: '',
        role: '',
        group: '',
    },
};

interface UpdateUserData {
    user: Person;
    role: string;
}

export const refreshCrewMembers = createAsyncThunk('users/refreshCrewMembers', async (location: string, { getState }) => {
    if (location !== 'EADS') {
        return [{}];
    } else {
        // @ts-ignore
        const { auth } = getState();
        return auth.availableCrewMembers;
    }
});

export const fetchCrewMembers = createAsyncThunk('users/fetchCrewMembers', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/crew`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const fetchMe = createAsyncThunk('users/fetchMe', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/users/me`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const updateUser = createAsyncThunk('users/updateUser', async (data: UpdateUserData, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'PUT',
            url: `${BASE_URL}/v1/users/${data.user.id}`,
            data: {
                ...data.user,
                role: data.role,
            },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const deleteUser = createAsyncThunk('users/deleteUser', async (userId: string, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'delete',
            url: `${BASE_URL}/v1/crew/${userId}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const createUser = createAsyncThunk('users/create', async (user: Person, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response: any = await axios({
            method: 'POST',
            url: `${BASE_URL}/v1/users/`,
            data: {
                ...user,
            },
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        console.warn(error);
        return rejectWithValue(error?.response?.data ? error.response.data : error);
    }
});

const usersSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchCrewMembers.fulfilled]: (state, action) => {
            state.users = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchCrewMembers.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchCrewMembers.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error fetching users.';
        },
        // @ts-ignore
        [fetchMe.fulfilled]: (state, action) => {
            state.me = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [fetchMe.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [fetchMe.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error fetching users.';
        },
        // @ts-ignore
        [updateUser.fulfilled]: (state, action) => {
            // state.me = action.payload;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [updateUser.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [updateUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error updating users.';
        },
        // @ts-ignore
        [deleteUser.fulfilled]: (state, action) => {
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [deleteUser.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [deleteUser.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error updating users.';
        },
        // @ts-ignore
        [createUser.fulfilled]: (state, action) => {
            state.users = [...state.users, action.payload];
        },
        // @ts-ignore
        [refreshCrewMembers.fulfilled]: (state, action) => {
            state.usersByLocation = action.payload;
        },
    },
});

export default usersSlice.reducer;
