import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utilities/Api';

interface RAPColumn {}

interface RAPRow {}

interface InitialState {
    columns: Array<RAPColumn>;
    rows: Array<RAPRow>;
    isLoading: boolean;
    error?: string;
}

let initialState: InitialState = {
    columns: [],
    rows: [],
    isLoading: true,
    error: undefined,
};

interface FetchRapProps {
    isoDate: string;
    selectedCrewMemberId: string;
    rapType: string;
}

export const fetchRap = createAsyncThunk(
    'rap/fetchRap',
    async (props: FetchRapProps, { getState, rejectWithValue }) => {
        const { isoDate, selectedCrewMemberId, rapType } = props;
        // @ts-ignore
        const { auth } = getState();

        try {
            const response = await axios({
                method: 'get',
                url: `${BASE_URL}/v1/reporting/rap?date=${isoDate}&crewMemberId=${selectedCrewMemberId}&rapType=${rapType}`,
                headers: {
                    Authorization: `Bearer ${auth.access_token}`,
                },
            });
            return {
                columns: response.data.columns,
                rows: response.data.rows,
            };
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    },
);

const rapSlice = createSlice({
    name: 'rap',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchRap.fulfilled]: (state, action) => {
            state.columns = action.payload.columns;
            state.rows = action.payload.rows;
            state.isLoading = false;
            state.error = undefined;
        },
        // @ts-ignore
        [fetchRap.pending]: (state, action) => {
            state.isLoading = true;
            state.error = undefined;
        },
        // @ts-ignore
        [fetchRap.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error fetching RAP.';
        },
    },
});

export default rapSlice.reducer;
