import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../utilities/Api';

import { CrewMember, LookbackEventType, Location } from '../static/interfaces/scheduling';

interface EventType {}
interface Priority {}
interface Category {}
interface ScopeType {}
interface ScopePosition {}
interface Qualification {}
interface InitialState {
    access_token: string;
    isLoading: boolean;
    error: string;
    loadingBranding: boolean;
    siteName: string;
    siteLogoUrl: string | null;
    landingImageUrl: string | null;
    defaultUserIconUrl: string | null;
    availableEventTypes: Array<EventType>;
    availablePriorities: Array<Priority>;
    availableLocations: Array<Location>;
    availableEventCategories: Array<Category>;
    availableScopeTypes: Array<ScopeType>;
    availableScopePositions: Array<ScopePosition>;
    availableCrewMembers: Array<CrewMember>;
    availableQualifications: Array<Qualification>;
    availableArmsMissions: Array<LookbackEventType>;
    availableRanks: Array<any>;
    availableEmployeeStatus: Array<any>;
    availableGroups: Array<any>;
    availableOffices: Array<any>;
}

interface LoginPayload {
    password: string;
    email: string;
}

let initialState: InitialState = {
    access_token: '',
    isLoading: false,
    error: '',
    loadingBranding: true,
    siteName: '',
    siteLogoUrl: null,
    landingImageUrl: null,
    defaultUserIconUrl: null,
    availableEventTypes: [],
    availablePriorities: [],
    availableLocations: [],
    availableEventCategories: [],
    availableScopeTypes: [], // TODO: Get from API
    availableScopePositions: [], // TODO: Get from API
    availableCrewMembers: [],
    availableQualifications: [],
    availableArmsMissions: [],
    availableRanks: [],
    availableEmployeeStatus: [],
    availableGroups: [],
    availableOffices: [],
};

export const verifyToken = createAsyncThunk('auth/verifyToken', async (token, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/users/me`,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const loginRequest = createAsyncThunk('auth/loginRequest', async (data: LoginPayload, { rejectWithValue }) => {
    try {
        const formData = new FormData();
        formData.append('email', data.email);
        formData.append('password', data.password);

        const response = await axios({
            method: 'post',
            url: `${BASE_URL}/auth/jwt/login`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' },
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const logoutRequest = createAsyncThunk('auth/logoutRequest', async (_, { rejectWithValue }) => {
    return true;
});

export const loadBranding = createAsyncThunk('auth/loadBranding', async (_, { rejectWithValue }) => {
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/branding`,
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const loadAvailableSelections = createAsyncThunk('auth/loadAvailableSelections', async (_, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    if (!auth?.access_token) {
        return;
    }

    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/selections`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

export const receivedUpdatedPersonnelItem = createAsyncThunk('auth/receivedUpdatedPersonnelItem', async (data, { getState, rejectWithValue }) => {
    const { auth } = getState();
    return auth.availableCrewMembers.map((crewMember) => {
        if (crewMember.id === data.id) {
            return data;
        }
        return crewMember;
    });
});

const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [verifyToken.fulfilled]: (state, action) => {
            state.access_token = action.meta.arg;
        },
        // @ts-ignore
        [loginRequest.fulfilled]: (state, action) => {
            state.access_token = action.payload.access;
            state.error = '';
            state.isLoading = false;
        },
        // @ts-ignore
        [loginRequest.pending]: (state, action) => {
            state.isLoading = true;
        },
        // @ts-ignore
        [loginRequest.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Incorrect username or password.';
        },
        // @ts-ignore
        [logoutRequest.fulfilled]: (state) => {
            state.access_token = '';
        },
        // @ts-ignore
        [loadBranding.rejected]: (state, action) => {
            state.loadingBranding = false;
        },
        // @ts-ignore
        [loadBranding.fulfilled]: (state, action) => {
            state.loadingBranding = false;
            state.siteName = action.payload.site_name;
            state.siteLogoUrl = action.payload.site_logo_url;
            state.landingImageUrl = action.payload.landing_image_url;
            state.defaultUserIconUrl = action.payload.default_user_icon_url;
        },
        // @ts-ignore
        [loadAvailableSelections.rejected]: (state, action) => {
            state.loadingAvailableSelections = false;
        },
        // @ts-ignore
        [loadAvailableSelections.fulfilled]: (state, action) => {
            state.loadingAvailableSelections = false;
            state.availableEventTypes = action?.payload?.available_event_types || [];
            state.availablePriorities = action?.payload?.available_priorities || [];
            state.availableLocations = action?.payload?.available_locations || [];
            state.availableEventCategories = action?.payload?.available_event_categories || [];
            state.availableCrewMembers = action?.payload?.available_crew_members || [];
            state.availableQualifications = action?.payload?.available_qualifications || [];
            state.availableArmsMissions = action?.payload?.available_arms_missions || [];
            state.availableRanks = action?.payload?.available_ranks || [];
            state.availableEmployeeStatus = action?.payload?.available_employee_status || [];
            state.availableGroups = action?.payload?.available_groups || [];
            state.availableOffices = action?.payload?.available_offices || [];
        },
        // @ts-ignore
        [receivedUpdatedPersonnelItem.fulfilled]: (state, action) => {
            state.availableCrewMembers = action.payload;
        },
    },
});

// for exporting regular actions.
// export const { loginRequest } = authSlice.actions;
export default authSlice.reducer;
