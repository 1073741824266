import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import toast from 'react-hot-toast';
import { BASE_URL } from '../utilities/Api';

interface InitialState {
    environmentals: any;
    approval_block: any;
    isLoading: boolean;
    error?: string;
}

let initialState: InitialState = {
    environmentals: {},
    approval_block: {},
    isLoading: true,
    error: undefined,
};

export const fetchApprovalWorkflow = createAsyncThunk('approvalWorkflow/fetchApprovalWorkflow', async (isoDate, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();

    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/approval_workflow?date=${isoDate}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });

        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

interface UpdateApprovalWorkflowProps {
    isoDate: string;
    data: {
        environmentals: any;
        approval_block: any;
    };
}

export const updateApprovalWorkflow = createAsyncThunk('approvalWorkflow/updateApprovalWorkflow', async (props: UpdateApprovalWorkflowProps, { getState, rejectWithValue }) => {
    const { isoDate, data } = props;

    // @ts-ignore
    const { auth } = getState();

    try {
        const response = await axios({
            method: 'put',
            url: `${BASE_URL}/v1/approval_workflow?date=${isoDate}`,
            data,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        toast.success('Workflow updated.');
        return response.data;
    } catch (error: any) {
        toast.error('An error occurred.');
        return rejectWithValue(error.response.data);
    }
});

export const updateEnvironmentalProperty = createAsyncThunk('approvalWorkflow/updateEnvironmentalProperty', async (props, { getState, rejectWithValue }) => {
    return props;
});

export const getEventsByDateAndType = createAsyncThunk('approvalWorkflow/getEventsByDateAndType', async (props: { isoDate: string; type: 'Air' | 'Ground' }, { getState, rejectWithValue }) => {
    // @ts-ignore
    const { auth } = getState();
    try {
        const response = await axios({
            method: 'get',
            url: `${BASE_URL}/v1/approval_workflow/events?start=${`${props.isoDate}T00:00:00Z`}&end=${`${props.isoDate}T23:59:59Z`}&event_type=${props.type}`,
            headers: {
                Authorization: `Bearer ${auth.access_token}`,
            },
        });
        return response.data;
    } catch (error: any) {
        return rejectWithValue(error.response.data);
    }
});

const approvalWorkflowSlice = createSlice({
    name: 'approval_workflow',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        // @ts-ignore
        [fetchApprovalWorkflow.fulfilled]: (state, action) => {
            state.environmentals = action.payload.environmentals;
            state.approval_block = action.payload.approval_block;
            state.isLoading = false;
            state.error = undefined;
        },
        // @ts-ignore
        [updateApprovalWorkflow.fulfilled]: (state, action) => {
            state.environmentals = action.payload.environmentals;
            state.approval_block = action.payload.approval_block;
            state.isLoading = false;
            state.error = undefined;
        },
        // @ts-ignore
        [fetchApprovalWorkflow.pending]: (state, action) => {
            state.isLoading = true;
            state.error = undefined;
        },
        // @ts-ignore
        [fetchApprovalWorkflow.rejected]: (state, action) => {
            state.isLoading = false;
            state.error = 'Error fetching approval workflow.';
        },
        // @ts-ignore
        [updateEnvironmentalProperty.fulfilled]: (state, action) => {
            state.environmentals = {
                ...state.environmentals,
                [action.payload.propertyName]: action.payload.value,
            };
        },
    },
});

export default approvalWorkflowSlice.reducer;
